
// Funcionando bien sin tailwins en el indexedDB.css

// import React, { useEffect, useState, useContext } from "react";
// import { Link } from "react-router-dom";
// import "./AnuncionVarios.css";
// import axios from 'axios';
// import { SearchContext } from '../SearchContext/SearchContext';
// import Swal from 'sweetalert2';
// import Redes from "../Redes/Redes";

// const AnuncionVarios = ({ data }) => {
//   const [favorites, setFavorites] = useState([]);
//   const { searchTerm, setSearchTerm } = useContext(SearchContext);

//   useEffect(() => {
//     const storedFavorites = JSON.parse(localStorage.getItem('favoriteItems'));
//     if (storedFavorites) {
//       setFavorites(storedFavorites);
//     }
//   }, []);

//   useEffect(() => {
//     if (data.length === 0 && searchTerm) {
//       Swal.fire({
//         title: 'No se encontraron resultados',
//         icon: 'warning',
//         confirmButtonColor: '#3085d6',
//         confirmButtonText: 'OK'
//       }).then(() => {
//         setSearchTerm('');
//       });
//     }
//   }, [data, searchTerm, setSearchTerm]);

//   const addToFavorites = async (itemId) => {
//     if (favorites.includes(itemId)) {
//       const updatedFavorites = favorites.filter((favId) => favId !== itemId);
//       setFavorites(updatedFavorites);
//       localStorage.setItem('favoriteItems', JSON.stringify(updatedFavorites));
//     } else {
//       const updatedFavorites = [...favorites, itemId];
//       setFavorites(updatedFavorites);
//       localStorage.setItem('favoriteItems', JSON.stringify(updatedFavorites));

//       try {
//         const response = await axios.post('https://asijeminapis.website:4000/favoritos/agregar', { convenioId: itemId });
//       } catch (error) {
//         console.error('Error al agregar el favorito en la base de datos:', error);
//       }
//     }
//   };

//   const isFavorite = (itemId) => {
//     return favorites.includes(itemId);
//   };

//   if (data.length === 0) {
//     return <div></div>; 
//   }

//   return (
//     <div className="container content-card">
//       <div className="row">
//         <div className="col-12 col-lg-9">
//           <div className="row justify-content-around">
//             {data.map((item) => (
//               <div className="col-12 col-sm-6 col-md-4 mb-3 conetenedor-cards" key={item._id}>
//                 <div className="card card-anuncios mx-auto w-100 cards-principales">
//                   <div className="contentimg">
//                     <img src={item.imagen[0]} alt={item.nombre} className="card-img top" />
//                     {item.destacado === 1 && (
//                       <p className='etiqueta-recomendados'><i className="fa-solid fa-crown"></i>Recomendado</p>
//                     )}
//                   </div>
//                   <div className="card-body">
//                     <div className="titulo-card-principal">
//                       <h5 className="card-title">{item.nombre}</h5>
//                     </div>
//                     <p className="card-text txtcard">{item.descripcion}</p>
//                     <div className="actions-row">
//                       <Link to={`/convenios/${item._id}`} className="subtitle_line">
//                         Saber Más <i className="fas fa-arrow-right-long"></i>
//                       </Link>
//                       {isFavorite(item._id) ? (
//                         <i className="fa-solid fa-heart favorito-corazon" onClick={() => addToFavorites(item._id)}></i>
//                       ) : (
//                         <i className="fa-regular fa-heart" onClick={() => addToFavorites(item._id)}></i>
//                       )}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//         <div className="col-12 col-lg-3">
//           <Redes />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AnuncionVarios;






import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import "./AnuncionVarios.css";
import axios from 'axios';
import { SearchContext } from '../SearchContext/SearchContext';
import Swal from 'sweetalert2';
import Redes from "../Redes/Redes";

const AnuncionVarios = ({ data }) => {
  const [favorites, setFavorites] = useState([]);
  const { searchTerm, setSearchTerm } = useContext(SearchContext);

  useEffect(() => {
    const storedFavorites = JSON.parse(localStorage.getItem('favoriteItems'));
    if (storedFavorites) {
      setFavorites(storedFavorites);
    }
  }, []);

  useEffect(() => {
    if (data.length === 0 && searchTerm) {
      Swal.fire({
        title: 'No se encontraron resultados',
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
      }).then(() => {
        setSearchTerm('');
      });
    }
  }, [data, searchTerm, setSearchTerm]);

  const addToFavorites = async (itemId) => {
    if (favorites.includes(itemId)) {
      const updatedFavorites = favorites.filter((favId) => favId !== itemId);
      setFavorites(updatedFavorites);
      localStorage.setItem('favoriteItems', JSON.stringify(updatedFavorites));
    } else {
      const updatedFavorites = [...favorites, itemId];
      setFavorites(updatedFavorites);
      localStorage.setItem('favoriteItems', JSON.stringify(updatedFavorites));

      try {
        await axios.post('https://asijeminapis.website:4000/favoritos/agregar', { convenioId: itemId });
      } catch (error) {
        console.error('Error al agregar el favorito en la base de datos:', error);
      }
    }
  };

  const isFavorite = (itemId) => {
    return favorites.includes(itemId);
  };

  if (data.length === 0) {
    return <div></div>;
  }

  return (
    <div className="container content-card">
      <div className="row">
        <div className="col-12 col-lg-9">
          <div className="row justify-content-around">
            {data.map((item) => (
              <div className="col-6 col-sm-6 col-md-4 mb-3 conetenedor-cards" key={item._id}>
                <div className="card card-anuncios mx-auto w-100 cards-principales">
                  <div className="contentimg">
                    <img src={item.imagen[0]} alt={item.nombre} className="card-img top" />
                    {item.destacado === 1 && (
                      <p className='etiqueta-recomendados'>
                        <i className="fa-solid fa-crown"></i>Recomendado
                      </p>
                    )}
                  </div>
                  <div className="card-body">
                    <div className="titulo-card-principal">
                      <h5 className="card-title">{item.nombre}</h5>
                    </div>
                    <p className="card-text txtcard">{item.descripcion}</p>
                    <div className="actions-row">
                      <Link to={`/convenios/${item._id}`} className="subtitle_line">
                        Saber Más <i className="fas fa-arrow-right-long"></i>
                      </Link>
                      {isFavorite(item._id) ? (
                        <i className="fa-solid fa-heart favorito-corazon" onClick={() => addToFavorites(item._id)}></i>
                      ) : (
                        <i className="fa-regular fa-heart" onClick={() => addToFavorites(item._id)}></i>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-12 col-lg-3">
          <Redes />
        </div>
      </div>
    </div>
  );
};

export default AnuncionVarios;





// import React, { useEffect, useState, useContext } from "react";
// import { Link } from "react-router-dom";
// import "./AnuncionVarios.css";
// import axios from 'axios';
// import { SearchContext } from '../SearchContext/SearchContext';
// import Swal from 'sweetalert2';
// import Redes from "../Redes/Redes";
// import { useMediaQuery } from 'react-responsive'; // Importa useMediaQuery

// const AnuncionVarios = ({ data }) => {
//   const [favorites, setFavorites] = useState([]);
//   const { searchTerm, setSearchTerm } = useContext(SearchContext);
  
//   // Detecta si es un dispositivo móvil
//   const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

//   useEffect(() => {
//     const storedFavorites = JSON.parse(localStorage.getItem('favoriteItems'));
//     if (storedFavorites) {
//       setFavorites(storedFavorites);
//     }
//   }, []);

//   useEffect(() => {
//     if (data.length === 0 && searchTerm) {
//       Swal.fire({
//         title: 'No se encontraron resultados',
//         icon: 'warning',
//         confirmButtonColor: '#3085d6',
//         confirmButtonText: 'OK'
//       }).then(() => {
//         setSearchTerm('');
//       });
//     }
//   }, [data, searchTerm, setSearchTerm]);

//   const addToFavorites = async (itemId) => {
//     if (favorites.includes(itemId)) {
//       const updatedFavorites = favorites.filter((favId) => favId !== itemId);
//       setFavorites(updatedFavorites);
//       localStorage.setItem('favoriteItems', JSON.stringify(updatedFavorites));
//     } else {
//       const updatedFavorites = [...favorites, itemId];
//       setFavorites(updatedFavorites);
//       localStorage.setItem('favoriteItems', JSON.stringify(updatedFavorites));

//       try {
//         await axios.post('https://asijeminapis.website:4000/favoritos/agregar', { convenioId: itemId });
//       } catch (error) {
//         console.error('Error al agregar el favorito en la base de datos:', error);
//       }
//     }
//   };

//   const isFavorite = (itemId) => {
//     return favorites.includes(itemId);
//   };

//   if (data.length === 0) {
//     return <div></div>;
//   }

//   return (
//     <div className="container content-card">
//       <div className="row">
//         <div className="col-12 col-lg-9">
//           <div className="row justify-content-around">
//             {data.map((item) => (
//               <div className="col-6 col-sm-6 col-md-4 mb-3 conetenedor-cards" key={item._id}>
//                 <div className="card card-anuncios mx-auto w-100 cards-principales">
//                   <div className="contentimg">
//                     <img src={item.imagen[0]} alt={item.nombre} className="card-img top" />
//                     {item.destacado === 1 && (
//                       <p className='etiqueta-recomendados'>
//                         <i className="fa-solid fa-crown"></i>Recomendado
//                       </p>
//                     )}
//                   </div>
//                   <div className="card-body">
//                     <div className="titulo-card-principal">
//                       <h5 className="card-title">{item.nombre}</h5>
//                     </div>
//                     <p className="card-text txtcard">{item.descripcion}</p>
//                     <div className="actions-row">
//                       <Link to={`/convenios/${item._id}`} className="subtitle_line">
//                         Saber Más <i className="fas fa-arrow-right-long"></i>
//                       </Link>
//                       {isFavorite(item._id) ? (
//                         <i className="fa-solid fa-heart favorito-corazon" onClick={() => addToFavorites(item._id)}></i>
//                       ) : (
//                         <i className="fa-regular fa-heart" onClick={() => addToFavorites(item._id)}></i>
//                       )}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//         {/* Renderiza el componente Redes solo si no es móvil */}
//         {!isMobile && (
//           <div className="col-12 col-lg-3">
//             <Redes />
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default AnuncionVarios;
