

import { Text } from "@tremor/react";
import PDFGenerator from "../PdfGenerator/PdfGenerator.jsx";
import { Helmet } from 'react-helmet';
import "./ComercioComponent.css";


const ComercioComponent = ({ item }) => {
  const year = item?.validez ? parseInt(item.validez.split('-')[2]) : null;

  return (
    <>
      <Helmet>
        <title> {item.nombre} | Accion Social ASIJEMIN</title>
      </Helmet>

      <div className="tw-flex tw-bg-[#f3f3f3] tw-flex-col md:tw-flex-row tw-w-full tw-px-4 tw-py-4">
        {/* Imagen */}
        <div className="tw-w-full md:tw-w-1/2 tw-mb-4 md:tw-mb-0">
        <div className="tw-relative tw-w-[90%] tw-h-auto tw-rounded-lg lg:tw-w-full tw-mx-auto tw-overflow-hidden divimg">
            <img
              src={item.imagen[0]}
              alt={item.nombre || "Imagen del convenio"}
              className="tw-w-full tw-h-auto"
            />
            {item.destacado && (
               <div className="fabv">
               <i className="fas fa-crown tw-text-base"></i>
             </div>
            )}
          </div>
        </div>

        {/* Información */}
        <div className="tw-w-full md:tw-w-1/2 tw-flex tw-flex-col">
          <div className="tw-bg-[#e0e0e0] tw-p-4 tw-text-center md:tw-text-left">
            <h2 className="tw-text-2xl md:tw-text-3xl tw-font-bold tw-text-[#707173]">
              {item.nombre}
            </h2>
          </div>

          <div className="tw-bg-[#eae8e8] tw-h-10 tw-px-4">
            <h2 className="tw-text-[#707173] tw-font-normal tw-pt-2">
              <i className="fa-solid fa-location-dot tw-mr-2"></i> {item.direccion} - {item.provincia} - {item.localidad}
            </h2>
          </div>

          <div className="tw-flex tw-flex-col md:tw-flex-row">
            <div className="tw-w-full md:tw-w-3/5 tw-p-4 tw-bg-slate-50">
              {item.descripcion?.split("\\n").map((linea, i) => (
                <p key={i}>{linea}</p>
              ))}
            </div>

            <div className="tw-w-full md:tw-w-2/5 tw-px-2 tw-flex tw-flex-col">
              <div className="tw-mb-4">
                {item.descuento !== 0 && (
                  <div className="tw-h-10 tw-bg-slate-500 tw-border-b tw-border-white tw-mb-2">
                    <p className="tw-text-slate-200 tw-px-4 tw-py-2">
                      <i className="fa-solid fa-percent tw-mr-2"></i> {item.descuento}%
                    </p>
                  </div>
                )}

                {item.telefono?.toString().length > 4 && (
                  <div className="tw-h-10 tw-bg-slate-500 tw-border-b tw-border-white tw-mb-2">
                    <p className="tw-text-slate-200 tw-px-4 tw-py-2">
                      <i className="fa-solid fa-phone tw-mr-2"></i> {item.telefono}
                    </p>
                  </div>
                )}

                {item.whatsaap?.toString().length > 4 && (
                  <div className="tw-h-10 tw-bg-slate-500 tw-border-b tw-border-white tw-mb-2">
                    <p className="tw-text-slate-200 tw-px-4 tw-py-2">
                      <i className="fa-brands fa-whatsapp tw-mr-2"></i> {item.whatsaap}
                    </p>
                  </div>
                )}

                {year > 2020 && (
                  <div className="tw-h-20 tw-bg-slate-500 tw-border-b tw-border-white tw-mb-2">
                    <Text className="subt tw-px-4 tw-py-2"> Validez de la oferta</Text>
                    <p className="tw-text-slate-200 tw-px-4 tw-py-2">
                      <i className="fa-regular fa-calendar-days tw-mr-2"></i> {item.validez}
                    </p>
                  </div>
                )}

                <div className="tw-h-20 tw-bg-slate-500 tw-border-t tw-border-white tw-flex tw-items-center tw-justify-center tw-mt-auto">
                  <PDFGenerator item={item} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComercioComponent;
