import React from 'react';
import './ModalLogin.css';

const ModalLogin = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Iniciar Sesión</h2>
        <input type="text" placeholder="Usuario" className="modal-input" />
        <input type="password" placeholder="Contraseña" className="modal-input" />
        <button className="modal-button">Iniciar Sesión</button>
        <p className="modal-forgot">
          <a href="#">Olvidé mis datos de acceso</a>
        </p>
        <button className="modal-close" onClick={onClose}>Cerrar</button>
      </div>
    </div>
  );
};

export default ModalLogin;
