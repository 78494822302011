// import React, { useState, useEffect, useContext } from "react";
// import "./Redes.css";
// import { FaFacebookF, FaInstagram, FaLinkedin } from "react-icons/fa";
// import axios from 'axios';
// import { SearchContext } from '../SearchContext/SearchContext';
// import { motion } from "framer-motion"




// const Redes = () => {
//   const [data, setData] = useState([]);
//   const [error, setError] = useState(null);
//   const { searchTerm } = useContext(SearchContext);  // Usamos el contexto
//   const [avisos, setAvisos] = useState([]); 

//   useEffect(() => {
//     const fetchAvisos = async () => {
//       try {
//         const response = await axios.get('https://asijeminapis.website:4000/avisos');
//         console.log('Respuesta de la API:', response.data);
//         if (Array.isArray(response.data)) {
//           setData(response.data);
//         } else if (response.data.message) {
//           setData(response.data.message);
//         } else {
//           setError('La respuesta de la API no contiene un listado de avisos.');
//         }
//       } catch (error) {
//         setError('No se pudo obtener la respuesta de la API.');
//         console.error('Error al obtener datos:', error);
//       }
//     };

//     fetchAvisos();
//   }, []);

//   useEffect(() => {
//     setAvisos(data.filter(item => item.activo === true)); // Chequea si 'activo' es verdadero
//   }, [data]);

//   return (
//     <>
//       <div className="redes-container">
//         <h5>Seguinos en: </h5>
//         <ul className="nav-rsociales">
//           <li>

        
//             <motion.button
//                 whileHover={{ scale: 1.1 }}
//                 whileTap={{ scale: 0.9 }}
//             >
                
//             <a
//               href="https://www.facebook.com/asijemin.org.ar"
//               target="_blank"
//               rel="noopener noreferrer"
//             >
//               <FaFacebookF className="redesociales" />
//             </a>
//             </motion.button>



//           </li>
//           <li>
//           <motion.button
//                 whileHover={{ scale: 1.1 }}
//                 whileTap={{ scale: 0.9 }}
//             >

//             <a
//               href="https://www.instagram.com/asijeminsindicato/"
//               target="_blank"
//               rel="noopener noreferrer"
//             >
//               <FaInstagram className="redesociales" />
//             </a>

//             </motion.button>
//           </li>
//           <li>
//           <motion.button
//                 whileHover={{ scale: 1.1 }}
//                 whileTap={{ scale: 0.9 }}
//             >
//             <a
//               href="https://www.linkedin.com/in/asijemin-sindicato-jer%C3%A1rquico-minero-90b25710b/"
//               target="_blank"
//               rel="noopener noreferrer"
//             >
//               <FaLinkedin className="redesociales" />
//             </a>
//             </motion.button>
//           </li>
//         </ul>
//       </div>

//       {/* Unirse al canal de WhatsApp */}
//       <div>
//         <a
//           href="https://whatsapp.com/channel/0029VaJ5rir0LKZGZfok5p0F"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           <div className="redes-canal canal"></div>
//         </a>
//       </div>

//       {/* Nueva afiliación */}
//       <div>
//         <a
//           href="https://miafiliacion.asijemin.org.ar/"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           <div className="redes-canal afiliacion"></div>
//         </a>
//       </div>

//       {/* Avisos */}
   
//       <div className="avi">
//         {avisos.length > 0 ? (
//           avisos.map(item => (
//             <a key={item._id} href={item.url} target="_blank" rel="noopener noreferrer">
//               <div className="notlat">
//                 <img src={item.imagen} alt={item._id} className="imgAviso"/>
//               </div>
//             </a>
//           ))
//         ) : (
//           <p>No hay avisos disponibles.</p>  
//         )}
//       </div>



//     </>
//   );
// };

// export default Redes;










import React, { useState, useEffect, useContext } from "react";
import "./Redes.css";
import { FaFacebookF, FaInstagram, FaLinkedin } from "react-icons/fa";
import axios from 'axios';
import { SearchContext } from '../SearchContext/SearchContext';
import { motion } from "framer-motion";
import { useMediaQuery } from 'react-responsive'; // Importa useMediaQuery para detectar dispositivos móviles

const Redes = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const { searchTerm } = useContext(SearchContext);
  const [avisos, setAvisos] = useState([]);
  
  // Detectar si el dispositivo es móvil
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  useEffect(() => {
    const fetchAvisos = async () => {
      try {
        const response = await axios.get('https://asijeminapis.website:4000/avisos');
        if (Array.isArray(response.data)) {
          setData(response.data);
        } else if (response.data.message) {
          setData(response.data.message);
        } else {
          setError('La respuesta de la API no contiene un listado de avisos.');
        }
      } catch (error) {
        setError('No se pudo obtener la respuesta de la API.');
        console.error('Error al obtener datos:', error);
      }
    };

    fetchAvisos();
  }, []);

  useEffect(() => {
    setAvisos(data.filter(item => item.activo === true)); // Chequea si 'activo' es verdadero
  }, [data]);

  // Vista para dispositivos móviles (con slider horizontal)
  const MobileView = () => (
    <div className="mobile-slider">
      <div className="slider-wrapper">
        <div className="redes-container">
          <h5>Seguinos en: </h5>
          <ul className="nav-rsociales">
            <li>
              <motion.button whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                <a href="https://www.facebook.com/asijemin.org.ar" target="_blank" rel="noopener noreferrer">
                  <FaFacebookF className="redesociales" />
                </a>
              </motion.button>
            </li>
            <li>
              <motion.button whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                <a href="https://www.instagram.com/asijeminsindicato/" target="_blank" rel="noopener noreferrer">
                  <FaInstagram className="redesociales" />
                </a>
              </motion.button>
            </li>
            <li>
              <motion.button whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                <a href="https://www.linkedin.com/in/asijemin-sindicato-jer%C3%A1rquico-minero-90b25710b/" target="_blank" rel="noopener noreferrer">
                  <FaLinkedin className="redesociales" />
                </a>
              </motion.button>
            </li>
          </ul>
        </div>

        {/* Unirse al canal de WhatsApp */}
        <a href="https://whatsapp.com/channel/0029VaJ5rir0LKZGZfok5p0F" target="_blank" rel="noopener noreferrer">
          <div className="redes-canal canal"></div>
        </a>

        {/* Nueva afiliación */}
        <a href="https://miafiliacion.asijemin.org.ar/" target="_blank" rel="noopener noreferrer">
          <div className="redes-canal afiliacion"></div>
        </a>

        {/* Avisos */}
        {avisos.length > 0 ? (
          avisos.map(item => (
            <a key={item._id} href={item.url} target="_blank" rel="noopener noreferrer">
              <div className="notlat">
                <img src={item.imagen} alt={item._id} className="imgAviso" />
              </div>
            </a>
          ))
        ) : (
          <p>No hay avisos disponibles.</p>
        )}
      </div>
    </div>
  );

  // Vista para desktop
  const DesktopView = () => (
    <>
      <div className="redes-container">
        <h5>Seguinos en: </h5>
        <ul className="nav-rsociales">
          <li>
            <motion.button whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <a href="https://www.facebook.com/asijemin.org.ar" target="_blank" rel="noopener noreferrer">
                <FaFacebookF className="redesociales" />
              </a>
            </motion.button>
          </li>
          <li>
            <motion.button whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <a href="https://www.instagram.com/asijeminsindicato/" target="_blank" rel="noopener noreferrer">
                <FaInstagram className="redesociales" />
              </a>
            </motion.button>
          </li>
          <li>
            <motion.button whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <a href="https://www.linkedin.com/in/asijemin-sindicato-jer%C3%A1rquico-minero-90b25710b/" target="_blank" rel="noopener noreferrer">
                <FaLinkedin className="redesociales" />
              </a>
            </motion.button>
          </li>
        </ul>
      </div>

      {/* Unirse al canal de WhatsApp */}
      <div>
        <a href="https://whatsapp.com/channel/0029VaJ5rir0LKZGZfok5p0F" target="_blank" rel="noopener noreferrer">
          <div className="redes-canal canal"></div>
        </a>
      </div>

      {/* Nueva afiliación */}
      <div>
        <a href="https://miafiliacion.asijemin.org.ar/" target="_blank" rel="noopener noreferrer">
          <div className="redes-canal afiliacion"></div>
        </a>
      </div>

      {/* Avisos */}
      <div className="avi">
        {avisos.length > 0 ? (
          avisos.map(item => (
            <a key={item._id} href={item.url} target="_blank" rel="noopener noreferrer">
              <div className="notlat">
                <img src={item.imagen} alt={item._id} className="imgAviso" />
              </div>
            </a>
          ))
        ) : (
          <p>No hay avisos disponibles.</p>
        )}
      </div>
    </>
  );

  return (
    <>
      {isMobile ? <MobileView /> : <DesktopView />}
    </>
  );
};

export default Redes;


