import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import phoneIcon from './phone.png'


import "./PdfGenerator.css";
// import CantidadIconos from "../CantidadIconos/CantidadIconos";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#fff",
    padding: 16,
  },
  section: {
    width: "100%", // Ajusta el ancho de la vista padre al 100%
    padding: 8,
    height: "auto",
  },
  image: {
    width: "100%",
    height: "auto",
  },
  titulo: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#707173",
    width: "97%",
    marginLeft: "6px", // Esto establecerá el margen izquierdo
    marginTop: "10px", // Esto establecerá el margen superior
  },
  descripcion: {
    fontSize: 12,
    fontWeight: "normal",
    marginTop: 20,
    marginLeft: "10px",
  },
  nombreYDireccion: {
    display: "flex",
    flexDirection: "column",
    fontSize: 18, // Tamaño de fuente para el nombre
    backgroundColor: "#e0e0e0",
    height: "auto",
    paddingBottom: "7px",
  },

  dir:{
    marginBottom: "7px",
    backgroundColor: "#adabab",
    padding: "5px",
  },
  direccion: {
    fontSize: 12, // Tamaño de fuente para la dirección
    marginTop: 8,
    color: "#e0e0e0",
    marginLeft: "6px", // Esto establecerá el margen izquierdo
  
  },
  cuerpo:{
    display: "flex",
    flexDirection: "column",
    fontSize: 18, // Tamaño de fuente para el nombre
    backgroundColor: "#e0e0e0",
    height: "auto",
    paddingBottom: "7px",
    marginTop: "10px",
  },
  info:{
    fontSize: 18,
    fontWeight: "bold",
    width: "40%",
    marginTop: 20,
    marginLeft: "15px",
  },
});

const PDFDocument = ({ item }) => (
    <Document>
      <Page size="A4" style={styles.page}>
       
  
        <View style={styles.section}>
        <View style={styles.nombreYDireccion}>
          <Text style={styles.titulo}> {item.nombre}   </Text>
         
        </View>
        <View style={styles.dir}>
           <Text style={styles.direccion}> <i className="fa-solid fa-location-dot mr-2"></i> {item.direccion} - {item.provincia} - {item.localidad}</Text>
        </View>

        <View style={{...styles.cuerpo, flexDirection: 'row'}}>
          <View style={{flex: 0.6}}>
            {item.descripcion.split("\\n").map((linea, i) => (
              <Text style={styles.descripcion} key={i}>
                {linea}
              </Text>
            ))}
          </View>
          <View style={{flex: 0.4}}>
          
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
  <Image src={phoneIcon} style={{ width: 12, height: 12 }} />
  <Text style={styles.info}>{item.telefono}</Text>
</View>
          </View>
        </View>   

      </View>
    </Page>
  </Document>
  );

  const PDFGenerator = ({ item }) => {
    const [imageData, setImageData] = useState(null);
  
    useEffect(() => {
      axios.get(item.imagen[0], { responseType: 'arraybuffer' })
        .then(response => {
          const base64 = btoa(
            new Uint8Array(response.data)
              .reduce((data, byte) => data + String.fromCharCode(byte), '')
          );
          setImageData(`data:${response.headers['content-type'].toLowerCase()};base64,${base64}`);
        })
        .catch(error => {
          console.error('Error fetching image:', error);
        });
    }, [item]);

    return (
        <div>
          <PDFDownloadLink
            document={<PDFDocument item={item} imageData={imageData} />}
            fileName={`${item.nombre}.pdf`}
          >
            {({  loading }) => (
              <button className="green-button text-white font-bold py-2 px-4 rounded">
              {loading ? "Generando PDF..." : "Descargar información en PDF"}
            </button>
            )}
          </PDFDownloadLink>
        </div>
      );
    };

    export default PDFGenerator;