

import React, { useContext, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';
import axios from 'axios';
import { SearchContext } from '../SearchContext/SearchContext';

const Navbar = () => {
  const [favoritos, setFavoritos] = useState([]);
  const [categoriasDisponibles, setCategoriasDisponibles] = useState([]);
  const { searchTerm } = useContext(SearchContext);
  const [showNavbar, setShowNavbar] = useState(true);

  useEffect(() => {
    // Ocultar Navbar si hay algo en el searchTerm, mostrarlo si está vacío
    if (searchTerm) {
      setShowNavbar(false);
    } else {
      setShowNavbar(true);
    }
  }, [searchTerm]);

  const categorias = [
    { tipo: 'Comercio', icono: 'shopping_basket', nombre: 'Comercios' },
    { tipo: 'Turismo', icono: 'Travel_Explore', nombre: 'Turismo' },
    { tipo: 'Servicios', icono: 'Handshake', nombre: 'Servicios' },
  ];
  
  useEffect(() => {
    const storedFavoritos = JSON.parse(localStorage.getItem('favoriteItems')) || [];
    setFavoritos(storedFavoritos);

    const fetchConvenios = async () => {
      try {
        const response = await axios.get('https://asijeminapis.website:4000/convenios');
        const convenios = response.data.message;

        // Obtener un array de los tipos de convenios existentes
        const tiposDisponibles = [...new Set(convenios.map(convenio => convenio.tipo))];
        
       
        // Filtrar las categorías para mantener solo las que están disponibles
        const categoriasFiltradas = categorias.filter(categoria => 
          tiposDisponibles.includes(categoria.tipo)
        );

        setCategoriasDisponibles(categoriasFiltradas);
      } catch (error) {
        console.error('Error al obtener los convenios:', error);
      }
    };

    fetchConvenios();
  }, []);

  return (
    showNavbar && (
      <div className='navbar'>
        <div className='ml-auto contenedor_iconos'>
          {categoriasDisponibles.map((categoria, index) => (
            <div className="contbtn" key={index}>
              <NavLink to={`/categoria/${categoria.tipo}`} className="material-symbols-outlined">
                {categoria.icono}
              </NavLink>
              <span>{categoria.nombre}</span>
            </div>
          ))}

          {favoritos.length > 0 && (
            <div className="contbtn">
              <NavLink to="/favoritos" className="material-symbols-outlined">
                favorite
              </NavLink>
              <span className='fn-rubik-500'>Favoritos</span>
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default Navbar;
