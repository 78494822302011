// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';


// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
 
//     <App />
 
// );



// import React from 'react';
// import ReactDOM from 'react-dom';
// import App from './App';
// import Swal from 'sweetalert2';  // Si querés usar una alerta visual

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(<App />);

// // Registrar el Service Worker
// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker.register('/service-worker.js').then((registration) => {
//       registration.onupdatefound = () => {
//         const installingWorker = registration.installing;
//         installingWorker.onstatechange = () => {
//           if (installingWorker.state === 'installed') {
//             if (navigator.serviceWorker.controller) {
//               // Mostrar SweetAlert cuando hay una nueva versión disponible
//               Swal.fire({
//                 title: 'Nueva versión disponible',
//                 text: 'Por favor, actualiza para aplicar los últimos cambios.',
//                 icon: 'info',
//                 showCancelButton: true,
//                 confirmButtonText: 'Actualizar',
//                 cancelButtonText: 'Más tarde'
//               }).then((result) => {
//                 if (result.isConfirmed) {
//                   window.location.reload();  // Recargar la página
//                 }
//               });
//             }
//           }
//         };
//       };
//     }).catch((error) => {
//       console.error('Error al registrar el Service Worker:', error);
//     });

//     // Escuchar mensajes del Service Worker
//     navigator.serviceWorker.addEventListener('message', (event) => {
//       if (event.data && event.data.type === 'NEW_VERSION') {
//         Swal.fire({
//           title: 'Nueva versión disponible',
//           text: 'Actualizando...',
//           icon: 'info',
//           confirmButtonText: 'Actualizar'
//         }).then(() => {
//           window.location.reload();
//         });
//       }
//     });
//   });
// }






import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Swal from 'sweetalert2';  // Importamos SweetAlert

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// Función para detectar si es un dispositivo iOS
const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
};

// Función para verificar si la PWA ya está instalada en modo standalone
const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

// Si es iOS y la PWA no está instalada, mostramos una alerta de instalación
if (isIos() && !isInStandaloneMode()) {
  Swal.fire({
    title: 'Instala esta aplicación',
    text: 'Para instalar la aplicación, abre el menú de compartir y selecciona "Agregar a la pantalla de inicio".',
    icon: 'info',
    confirmButtonText: 'Entendido'
  });
}

// Registrar el Service Worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js').then((registration) => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // Mostrar SweetAlert cuando hay una nueva versión disponible
              Swal.fire({
                title: 'Nueva versión disponible',
                text: 'Por favor, actualiza para aplicar los últimos cambios.',
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Actualizar',
                cancelButtonText: 'Más tarde'
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();  // Recargar la página
                }
              });
            }
          }
        };
      };
    }).catch((error) => {
      console.error('Error al registrar el Service Worker:', error);
    });

    // Escuchar mensajes del Service Worker
    navigator.serviceWorker.addEventListener('message', (event) => {
      if (event.data && event.data.type === 'NEW_VERSION') {
        Swal.fire({
          title: 'Nueva versión disponible',
          text: 'Actualizando...',
          icon: 'info',
          confirmButtonText: 'Actualizar'
        }).then(() => {
          window.location.reload();
        });
      }
    });
  });
}
