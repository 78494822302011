
// import './MarcoContainer.css';
// import AnuncionVarios from '../AnuncionVarios/AnuncionVarios';
// import React, { useEffect, useState, useContext } from "react";
// import axios from 'axios';
// import { SearchContext } from '../SearchContext/SearchContext';
// import Redes from '../Redes/Redes';

// const MarcoContainer = () => {
//   const [data, setData] = useState([]);
//   const [error, setError] = useState(null);
//   const { searchTerm } = useContext(SearchContext);  // Usamos el contexto
//   const [avisos, setAvisos] = useState([]); 

//   useEffect(() => {
//     const fetchConvenios = async () => {
//       try {
//         const response = await axios.get('https://asijeminapis.website:4000/convenios');
        
//         if (Array.isArray(response.data)) {
//           setData(response.data);
//         } else if (response.data.message) {
//           setData(response.data.message);
//         } else {
//           setError('La respuesta de la API no contiene un listado de convenios.');
//         }
//       } catch (error) {
//         setError('No se pudo obtener la respuesta de la API.');
//         console.error('Error al obtener datos:', error);
//       }
//     };

//     fetchConvenios();
//   }, []);

//   useEffect(() => {
//     const fetchAvisos = async () => {
//       try {
//         const response = await axios.get('https://asijeminapis.website:4000/avisos');
        
//         if (Array.isArray(response.data)) {
//           setAvisos(response.data);
//         } else if (response.data.message) {
//           setAvisos(response.data.message);
//         } else {
//           setError('La respuesta de la API no contiene un listado de convenios.');
//         }
//       } catch (error) {
//         setError('No se pudo obtener la respuesta de la API.');
//         console.error('Error al obtener datos:', error);
//       }
//     };

//     fetchAvisos();
//   }, []);

//   // Filtrado condicional: solo filtrar si hay un término de búsqueda
//   const filteredData = searchTerm 
//     ? data.filter(item => 
//         item.nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
//         item.provincia.toLowerCase().includes(searchTerm.toLowerCase())
//       )
//     : data;  // Si no hay búsqueda, mostrar todos los convenios

//   if (error) {
//     return <div>{error}</div>;
//   }

//   // Verifica si no hay resultados
//   const noResults = searchTerm && filteredData.length === 0;

//   return (
//     <div className='mt-5'>
//       {/* Mostrar "Resultado de la Búsqueda" si hay resultados, de lo contrario "Listado de Convenios" */}
//       {!noResults && (
//         <h2 className='text-3xl font-bold ml-3'>
//           {searchTerm && filteredData.length > 0 ? 'Resultado de la Búsqueda' : 'Listado de Convenios'}
//         </h2>
//       )}

//       {/* Ocultar la descripción si la búsqueda es satisfactoria o no hay resultados */}
//       {!noResults && (!searchTerm || filteredData.length === 0) && (
//         <p className='parrafo-seccion-anuncios ml-3 text-xl font-thin'>
//           Todos nuestros beneficios
//         </p>
//       )}

//       {/* <div className="section-anuncios">
//         <div className="anuncios mt-5"> */}
//         <div className="">
//         <div className="anuncios mt-5">
//           <AnuncionVarios data={filteredData} />
//         </div>


//       {/* <div>
//           <Redes  />
//       </div> */}

//       {/* avisos laterales */}
//         {/* <div className="avi">
//           {avisos.length > 0 ? (
//             avisos.map(item => (
//               <div key={item._id} className="notlat">
//                 <img src={item.imagen} alt={item._id} />
//               </div>
//             ))
//           ) : (
//             <p>No hay avisos disponibles.</p>  
//           )}
//         </div> */}


//       </div>
//     </div>
//   );
// };

// export default MarcoContainer;





// import './MarcoContainer.css';
// import AnuncionVarios from '../AnuncionVarios/AnuncionVarios';
// import React, { useEffect, useState, useContext } from "react";
// import axios from 'axios';
// import { SearchContext } from '../SearchContext/SearchContext';

// const MarcoContainer = () => {
//   const [data, setData] = useState([]);
//   const [error, setError] = useState(null);
//   const { searchTerm } = useContext(SearchContext);  // Usamos el contexto

//   useEffect(() => {
//     const fetchConvenios = async () => {
//       try {
//         const response = await axios.get('https://asijeminapis.website:4000/convenios');
        
//         if (Array.isArray(response.data)) {
//           setData(response.data);
//         } else if (response.data.message) {
//           setData(response.data.message);
//         } else {
//           setError('La respuesta de la API no contiene un listado de convenios.');
//         }
//       } catch (error) {
//         setError('No se pudo obtener la respuesta de la API.');
//         console.error('Error al obtener datos:', error);
//       }
//     };

//     fetchConvenios();
//   }, []);

//   useEffect(() => {
//     const fetchAvisos = async () => {
//       try {
//         const response = await axios.get('https://asijeminapis.website:4000/avisos');
        
//         if (Array.isArray(response.data)) {
//           setAvisos(response.data);
//         } else if (response.data.message) {
//           setAvisos(response.data.message);
//         } else {
//           setError('La respuesta de la API no contiene un listado de convenios.');
//         }
//       } catch (error) {
//         setError('No se pudo obtener la respuesta de la API.');
//         console.error('Error al obtener datos:', error);
//       }
//     };

//     fetchAvisos();
//   }, []);

//   // Filtrado condicional: solo filtrar si hay un término de búsqueda
//   const filteredData = searchTerm 
//     ? data.filter(item => 
//         item.nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
//         item.provincia.toLowerCase().includes(searchTerm.toLowerCase())
//       )
//     : data;  // Si no hay búsqueda, mostrar todos los convenios

//   if (error) {
//     return <div>{error}</div>;
//   }

//   // Verifica si no hay resultados
//   const noResults = searchTerm && filteredData.length === 0;

//   return (
//     <div className='mt-5'>
//       {/* Mostrar "Resultado de la Búsqueda" si hay resultados, de lo contrario "Listado de Convenios" */}
//       {!noResults && (
//         <h2 className={`tw-text-3xl tw-font-bold tw-ml-3 ${searchTerm ? 'search-results' : ''}`}>
//           {searchTerm && filteredData.length > 0 ? 'Resultado de la Búsqueda' : 'Listado de Convenios'}
//         </h2>
//       )}

//       {/* Ocultar la descripción si la búsqueda es satisfactoria o no hay resultados */}
//       {!noResults && (!searchTerm || filteredData.length === 0) && (
//         <p className='parrafo-seccion-anuncios tw-ml-3 tw-text-xl tw-font-thin'>
//           Todos nuestros beneficios
//         </p>
//       )}

//       <div className="anuncios mt-5">
//         <AnuncionVarios data={filteredData} />
//       </div>
//     </div>
//   );
// };

// export default MarcoContainer;








import './MarcoContainer.css';
import AnuncionVarios from '../AnuncionVarios/AnuncionVarios';
import React, { useEffect, useState, useContext } from "react";
import axios from 'axios';
import { SearchContext } from '../SearchContext/SearchContext';

const MarcoContainer = () => {
  const [data, setData] = useState([]);
  const [avisos, setAvisos] = useState([]);  // Definir estado para los avisos
  const [error, setError] = useState(null);
  const { searchTerm } = useContext(SearchContext);  // Usamos el contexto

  useEffect(() => {
    const fetchConvenios = async () => {
      try {
        const response = await axios.get('https://asijeminapis.website:4000/convenios');
        
        if (Array.isArray(response.data)) {
          setData(response.data);
        } else if (response.data.message) {
          setData(response.data.message);
        } else {
          setError('La respuesta de la API no contiene un listado de convenios.');
        }
      } catch (error) {
        setError('No se pudo obtener la respuesta de la API.');
        console.error('Error al obtener datos:', error);
      }
    };

    fetchConvenios();
  }, []);

  useEffect(() => {
    const fetchAvisos = async () => {
      try {
        const response = await axios.get('https://asijeminapis.website:4000/avisos');
        
        if (Array.isArray(response.data)) {
          setAvisos(response.data);  // Usar setAvisos para actualizar el estado
        } else if (response.data.message) {
          setAvisos(response.data.message);  // Usar setAvisos para actualizar el estado
        } else {
          setError('La respuesta de la API no contiene un listado de convenios.');
        }
      } catch (error) {
        setError('No se pudo obtener la respuesta de la API.');
        console.error('Error al obtener datos:', error);
      }
    };

    fetchAvisos();
  }, []);

  // Filtrado condicional: solo filtrar si hay un término de búsqueda
  const filteredData = searchTerm 
    ? data.filter(item => 
        item.nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.provincia.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : data;  // Si no hay búsqueda, mostrar todos los convenios

  if (error) {
    return <div>{error}</div>;
  }

  // Verifica si no hay resultados
  const noResults = searchTerm && filteredData.length === 0;

  return (
    <div className='mt-5'>
      {/* Mostrar "Resultado de la Búsqueda" si hay resultados, de lo contrario "Listado de Convenios" */}
      {!noResults && (
        <h2 className={`tw-text-3xl tw-font-bold tw-ml-3 ${searchTerm ? 'search-results' : ''}`}>
          {searchTerm && filteredData.length > 0 ? 'Resultado de la Búsqueda' : 'Listado de Convenios'}
        </h2>
      )}

      {/* Ocultar la descripción si la búsqueda es satisfactoria o no hay resultados */}
      {!noResults && (!searchTerm || filteredData.length === 0) && (
        <p className='parrafo-seccion-anuncios tw-ml-3 tw-text-xl tw-font-thin'>
          Todos nuestros beneficios
        </p>
      )}

      <div className="anuncios mt-5">
        <AnuncionVarios data={filteredData} />
      </div>
    </div>
  );
};

export default MarcoContainer;
