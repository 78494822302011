import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from 'axios';
import { Helmet } from 'react-helmet';
import '../AlojamientoComponent/AlojamientoComponentTailwinds.css';
import '../AlojamientoComponent/AlojamientoComponent.css';
import { FaWhatsapp , FaEnvelope } from "react-icons/fa"; // Importar íconos
import { BiWorld } from "react-icons/bi";
import { SiGoogleforms } from "react-icons/si";

const API_CONVENIOS = 'https://asijeminapis.website:4000/beneficios';

const BeneficiosDetail = () => {
  const { id } = useParams(); // Obtener el ID de la URL
  const [item, setItem] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBeneficio = async () => {
      try {
        const response = await axios.get(`https://asijeminapis.website:4000/beneficios/${id}`);
        if (response.data && response.data.message) {
          setItem(response.data.message); // Ajustar según cómo tu API devuelve los datos
        } else {
          setError('No se encontró el beneficio.');
        }
      } catch (error) {
        setError('Error al obtener los datos del beneficio.');
        console.error('Error al obtener los datos:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBeneficio();
  }, [id]);

  if (loading) {
    return (
      <div className="tw-flex tw-justify-center tw-items-center tw-h-screen">
        <img src="/spinner.gif" alt="Cargando..." className="tw-w-16 tw-h-16" />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!item) {
    return <div>No se encontró el beneficio.</div>;
  }

  // Verificamos si hay URLs en el array y las tomamos
  const urls = item.url && item.url.length > 0 ? item.url : [];
  // Verificamos si hay correos en el array y tomamos el primero
  const email = item.correo && item.correo.length > 0 ? item.correo[0] : null;
  const whatsapp = item.whatsapp && item.whatsapp.length > 0 ? item.whatsapp[0] : null;
  const gform = item.formulario && item.formulario.length > 0 ? item.formulario[0] : null;

  return (
    <>
      <Helmet>
        <title>{item.titulo} | Accion Social ASIJEMIN</title>
      </Helmet>

      <div className="tw-flex tw-bg-[#f3f3f3] tw-flex-col lg:tw-flex-row tw-w-full tw-px-4 tw-py-4">
        {/* Columna de imagen */}
        <div className="tw-w-full lg:tw-w-1/2">
          <div className="tw-relative tw-w-[90%] tw-h-auto tw-rounded-lg lg:tw-w-full tw-mx-auto tw-overflow-hidden divimg">
            <img
              src={item.imagen}
              alt={item.titulo || "Imagen del beneficio"}
              className="tw-w-full tw-h-auto"
            />
          </div>
        </div>

        {/* Columna de detalles */}
        <div className="tw-w-full lg:tw-w-1/2 tw-p-4">
          <div className="tw-bg-[#e0e0e0] tw-flex tw-items-center tw-rounded-lg tw-shadow-sm tw-py-4">
            <h2 className="tw-text-2xl md:tw-text-3xl tw-font-bold tw-text-[#707173] tw-px-2">
              {item.titulo}
            </h2>
          </div>

          <div className="tw-flex tw-my-4 tw-flex-col lg:tw-flex-row tw-bg-white tw-rounded-lg tw-shadow-md">
            <div className="tw-w-full lg:tw-w-4/5 tw-p-4">
              {item.descripcion?.split("\\n").map((linea, i) => (
                <p key={i}>{linea}</p>
              ))}
            </div>

            <div className="tw-w-full lg:tw-w-2/5 tw-p-4">
              {/* Mostrar botones solo si hay URLs en el array */}
              {urls.length > 0 && (
                urls.map((link, index) => (
                  <div key={index} className="tw-mb-4 tw-bg-slate-500 tw-rounded-lg tw-shadow-md tw-py-2 tw-px-4">
                    <a 
                      href={link} 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="tw-text-white tw-bg-slate-500 tw-rounded-lg tw-py-2 tw-px-4 tw-inline-block tw-flex tw-items-center"
                    >
                      <BiWorld className="tw-mr-2" /> Ir a la web 
                    </a>
                  </div>
                ))
              )}

              {/* Mostrar botón de email solo si hay un correo disponible */}
              {email && (
                <div className="tw-mb-4 tw-bg-blue-500 tw-rounded-lg tw-shadow-md tw-py-2 tw-px-4">
                  <a 
                    href={`mailto:${email}`} 
                    className="tw-text-white tw-bg-blue-500 tw-rounded-lg tw-py-2 tw-px-4 tw-inline-block tw-flex tw-items-center"
                  >
                    <FaEnvelope className="tw-mr-2" /> Enviar un correo
                  </a>
                </div>
              )}

              {whatsapp && (
                <div className="tw-mb-4 tw-bg-green-500 tw-rounded-lg tw-shadow-md tw-py-2 tw-px-4">
                  <a                      
                      target="_blank" 
                      rel="noopener noreferrer" 
                      href={`https://wa.me/${whatsapp}?text=Vi%20su%20anuncio%20en%20la%20página%20de%20Accion%20Social%20ASIJEMIN`} 
                      className="tw-text-white  tw-bg-green-500 tw-rounded-lg tw-py-2 tw-px-4 tw-inline-block tw-flex tw-items-center"
                  >
                    <FaWhatsapp className="tw-mr-2 tw-text-xl" />
                    <span className="tw-text-[15px]">Enviar WhatsApp</span>

                  </a>
                </div>
              )}

              {gform && (
                <div className="tw-mb-4 tw-bg-violet-500 tw-rounded-lg tw-shadow-md tw-py-2 tw-px-4">
                  <a 
                    href={gform} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="tw-text-white tw-bg-violet-500 tw-rounded-lg tw-py-2 tw-px-4 tw-inline-block tw-flex tw-items-center"
                  >
                    <SiGoogleforms className="tw-mr-2" /> Ir al Formulario
                  </a>
                </div>
              )}

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BeneficiosDetail;
