


import React, { useState, useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./NavSuperior.css";
import Logo from "./asijeminaniversario.svg";
import { FaUser, FaSearch, FaBars } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { SearchContext } from '../SearchContext/SearchContext';
import ModalLogin from "./ModalLogin";

const NavSuperior = () => {
  const { searchTerm, setSearchTerm } = useContext(SearchContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // Para el menú móvil

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const clearSearch = () => {
    setSearchTerm('');
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`nav-container ${scrolled ? "scrolled" : ""}`}>
      <div className="nav-content">
        {!scrolled ? (
          <div className="nav-logo">
            <a href="/">
              <img src={Logo} alt="ASIJEMIN Logo" className="logopng" />
            </a>
          </div>
        ) : (
          <a href="/">
            <div className="nav-logo-alt">
              <img src={Logo} alt="ASIJEMIN Logo" className="logopng" />
            </div>
          </a>
        )}

        {/* Barra de búsqueda y usuario */}
        <div className="nav-social-search">
          {!scrolled ? (
            <div className={`search-container ${searchTerm ? "active" : ""}`}>
              <FaSearch className="search-icon" />
              <input
                type="text"
                value={searchTerm}
                onChange={handleInputChange}
                placeholder="Buscar convenios..."
                className="search-input"
              />
              {searchTerm && !isModalOpen && (
                <button className="clear-search-btn" onClick={clearSearch}>
                  <IoMdClose className="clear-icon" />
                </button>
              )}
            </div>
          ) : (
            <div className="search-container-alt">
              <input
                type="text"
                value={searchTerm}
                onChange={handleInputChange}
                placeholder="Buscar..."
                className="search-input-alt"
              />
            </div>
          )}

          {!scrolled ? (
            <div className="user-icon-container">
              <FaUser className="user-icon" onClick={openModal} />
            </div>
          ) : (
            <div className="user-icon-container-alt">
              <FaUser className="user-icon-minimo " onClick={openModal} />
            </div>
          )}
        </div>

        {/* Botón para abrir el menú móvil */}
        <div className="mobile-menu-button" onClick={toggleMobileMenu}>
          <FaBars />
        </div>
      </div>

      {/* Navegación normal */}
      <div className={`nav-below ${scrolled ? "scrolled" : ""}`}>
        <div className="nav-links">
          <ul>
            {!scrolled ? (
              <>
                <NavLink className="btn-nav" to="/">
                  CONVENIOS
                </NavLink>
                <NavLink className="btn-nav" to="/beneficios_personales">
                  BENEFICIOS PERSONALES
                </NavLink>
              </>
            ) : (
              <>
                <div className="containerbtnsnav">
                  <NavLink className="btn-nav-compact" to="/">
                    CONVENIOS
                  </NavLink>
                  <NavLink className="btn-nav-compact" to="/beneficios_personales">
                    BENEFICIOS PERSONALES
                  </NavLink>
                </div>
              </>
            )}
          </ul>
        </div>
      </div>

      {/* Menú móvil */}
      <div className={`mobile-menu ${isMobileMenuOpen ? "open" : ""}`}>
        <ul>
          <li>
            <NavLink to="/" onClick={toggleMobileMenu}>
              Convenios
            </NavLink>
          </li>
          <li>
            <NavLink to="/beneficios_personales" onClick={toggleMobileMenu}>
              Beneficios Personales
            </NavLink>
          </li>
          <li>
            <FaUser className="user-icon-mobile" onClick={openModal} />
          </li>
        </ul>
      </div>

      <ModalLogin isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};

export default NavSuperior;
