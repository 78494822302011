

import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import '../../index.css';
import './ComunicadosLS.css';

const API_CONVENIOS = 'https://asijeminapis.website:4000/beneficios';

const ComunicadosLS = () => {
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await axios.get(API_CONVENIOS);
        // Verificar si la respuesta contiene un array de datos
        if (Array.isArray(response.data.message)) {
          setItems(response.data.message);
        } else {
          setError('La respuesta de la API no contiene un listado de beneficios.');
        }
      } catch (error) {
        setError('No se pudo obtener la respuesta de la API.');
        console.error('Error al obtener datos:', error);
      }
    };

    fetchItems();
  }, []);

  return (
    <>
      <Helmet>
        <title>Beneficios Personales | Accion Social ASIJEMIN</title>
      </Helmet>

      <div className='divider'>
        <h3 className='fn-montserrat'>Beneficios Personales</h3>
      </div>

      <div className="row">
        {error ? (
          <div>{error}</div>
        ) : (
          items.map((item) => (
            <div className="col-6 col-md-6 mb-4 contenedor-cards" key={item._id}>
              <div className="card card-anuncios mx-2 w-100 cards-principales" style={{ maxWidth: "427px" }}>
                
                <div className="contentimg">
                  <img src={item.imagen} alt={item.titulo} className="card-img top" />
                  {/* Si deseas agregar una etiqueta como "Recomendado" o algo similar, puedes hacerlo aquí */}
                </div>

                <div className="card-body">
                  <h5 className="card-title">{item.titulo}</h5>
                  <p className="card-text">{item.descripcion}</p>
                  <div className="card-actions">
                    {/* <Link to={`/beneficios_personales/${item._id}`} className="subtitle_line">Saber Más <i className="fas fa-arrow-right-long"></i></Link> */}
                    <Link to={`/beneficios_personales/${item._id}`} className="subtitle_line">Saber Más <i className="fas fa-arrow-right-long"></i></Link>

                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default ComunicadosLS;


