



// import { useParams } from "react-router-dom";
// import "./ItemDetailContainer.css";
// import CantidadIconos from "../CantidadIconos/CantidadIconos.jsx";
// import PDFGenerator from "../PdfGenerator/PdfGenerator.jsx";
// import ServicioComponent from "../ServicioComponent/ServicioComponent";
// import AlojamientoComponent from "../AlojamientoComponent/AlojamientoComponent";
// import React, { useEffect, useState } from "react";
// import axios from 'axios';

// const ItemDetailContainer = () => {
//   const { itemId } = useParams();
//   const [item, setItem] = useState(null); // Estado para almacenar los detalles del elemento
//   const [error, setError] = useState(null); // Estado para manejar errores

//   useEffect(() => {
//     const fetchItemDetails = async () => {
//       try {
//         const response = await axios.get(`https://asijeminapis.website:4000/convenios/${itemId}`);
        
//         console.log(response.data)

//         if (response.data) {
//           setItem(response.data);
//         } else {
//           setError('La respuesta de la API no contiene los detalles del convenio.');
//         }
//       } catch (error) {
//         setError('No se pudo obtener la respuesta de la API.');
//         console.error('Error al obtener datos:', error);
//       }
//     };

//     fetchItemDetails();
//   }, [itemId]);

//   if (error) {
//     return <div>{error}</div>;
//   }

//   // Si aún no se han cargado los datos, muestra un mensaje de carga
//   if (!item) {
//     return <div>Cargando...</div>;
//   }

//   // Verifica que todas las propiedades que `ServicioComponent` espera existan antes de renderizar
//   const validItem = item && item.tipo && (item.tipo === "alojamiento" || item.tipo === "servicio");

//   return (
//     <div>
//       {validItem ? (
//         item.tipo == "alojamiento" ? (
//           <AlojamientoComponent item={item} />
//         ) : (
//           <ServicioComponent item={item} />
//         )
//       ) : (
//         <div>Error: Los detalles del convenio no están completos o no son válidos.</div>
//       )}
//     </div>
//   );
// };

// export default ItemDetailContainer;




import { useParams } from "react-router-dom";
import "./ItemDetailContainer.css";
import ServicioComponent from "../ServicioComponent/ServicioComponent";
import AlojamientoComponent from "../AlojamientoComponent/AlojamientoComponent";
import React, { useEffect, useState } from "react";
import axios from 'axios';
import ComercioComponent from "../ComercioComponent/Comercio";
import '../AlojamientoComponent/AlojamientoComponentTailwinds.css';
import '../AlojamientoComponent/AlojamientoComponent.css';



const ItemDetailContainer = () => {
  const { itemId } = useParams();
  const [item, setItem] = useState(null); // Estado para almacenar los detalles del elemento
  const [error, setError] = useState(null); // Estado para manejar errores

  useEffect(() => {
    const fetchItemDetails = async () => {
      try {
        const response = await axios.get(`https://asijeminapis.website:4000/convenios/${itemId}`);
        
        console.log(response.data);

        if (response.data && response.data.message) {
          setItem(response.data.message); // Accede directamente a 'message' que contiene los datos reales
        } else {
          setError('La respuesta de la API no contiene los detalles del convenio.');
        }
      } catch (error) {
        setError('No se pudo obtener la respuesta de la API.');
        console.error('Error al obtener datos:', error);
      }
    };

    fetchItemDetails();
  }, [itemId]);

  if (error) {
    return <div>{error}</div>;
  }

  // Si aún no se han cargado los datos, muestra un mensaje de carga
  if (!item) {
    return <div>Cargando...</div>;
  }

  // Verifica que todas las propiedades que `ServicioComponent` espera existan antes de renderizar
  const validItem = item && item.tipo && (item.tipo === "Turismo" || item.tipo === "Servicios" || item.tipo === "Comercio");

  return (
    <div>
    {validItem ? (
      item.tipo === "Turismo" ? (
        <AlojamientoComponent item={item} />
      ) : item.tipo === "Servicios" ? (
        <ServicioComponent item={item} />
      ) : item.tipo === "Comercio" ? (
        <ComercioComponent item={item} />
      ) : null
    ) : (
      <div>Error: Los detalles del convenio no están completos o no son válidos.</div>
    )}
  </div>

  );
};

export default ItemDetailContainer;


