
// import React, { useState, useEffect } from 'react';
// import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import NavSuperior from './components/NavSuperior/NavSuperior';
// import NavSuperiorMobile from './components/NavSuperior/NavSuperiorMobile';
// import Navbar from './components/Navbar/Navbar';
// import Footer from './components/Footer/Footer';
// import Contenedor from './components/Contenedor/Contenedor';
// import Comunicados from './components/Comunicados/Comunicados';
// import ItemDetailContainer from './components/ItemDetailContainer/ItemDetailContainer';
// import Categorias from './components/Categorias/Categorias';
// import Favoritos from './components/Favoritos/Favoritos';
// import { ModoProvider } from './components/ModoContext/ModoContex';
// import { SearchProvider } from './components/SearchContext/SearchContext';
// import MobileBottomBar from './components/NavSuperior/MobileBottomBar';
// import InstallPromptModal from './components/InstallPromptModal/InstallPromptModal.jsx';  // Modal personalizado
// import './app.btninstall.css';

// function App() {
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
//   const [deferredPrompt, setDeferredPrompt] = useState(null);
//   const [showInstallPrompt, setShowInstallPrompt] = useState(false);

//   const handleResize = () => {
//     setIsMobile(window.innerWidth <= 768);
//   };

//   useEffect(() => {
//     window.addEventListener('resize', handleResize);
//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);

//   // Registrar el Service Worker
//   useEffect(() => {
//     if ('serviceWorker' in navigator) {
//       window.addEventListener('load', () => {
//         navigator.serviceWorker.register('/service-worker.js')
//           .then((registration) => {
//             console.log('Service Worker registrado con éxito:', registration);
//           })
//           .catch((error) => {
//             console.log('Error al registrar el Service Worker:', error);
//           });
//       });
//     }
//   }, []);

//   // Manejar el evento beforeinstallprompt para mostrar el modal
//   useEffect(() => {
//     const handleBeforeInstallPrompt = (e) => {
//       e.preventDefault(); // Prevenir que se muestre automáticamente
//       setDeferredPrompt(e); // Guardar el evento para usarlo más tarde

//       // Retrasar la aparición del modal 5 minutos (300,000 ms)
//       setTimeout(() => {
//         setShowInstallPrompt(true); // Mostrar el modal después de 5 minutos
//       }, 10);
//     };

//     window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

//     return () => {
//       window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
//     };
//   }, []);

//   // Función para manejar la instalación de la PWA cuando el usuario presione el botón
//   const handleInstallClick = () => {
//     if (deferredPrompt) {
//       deferredPrompt.prompt(); // Mostrar el prompt de instalación
//       deferredPrompt.userChoice.then((choiceResult) => {
//         if (choiceResult.outcome === 'accepted') {
//           console.log('El usuario aceptó la instalación');
//         } else {
//           console.log('El usuario rechazó la instalación');
//         }
//         setDeferredPrompt(null); // Limpiar el prompt después de la respuesta
//         setShowInstallPrompt(false); // Cerrar el modal
//       });
//     }
//   };

//   return (
//     <div className="App">
//       <BrowserRouter>
//         <SearchProvider>
//           <div className="content-wrap">
//             {isMobile ? <NavSuperiorMobile /> : <NavSuperior />}
//             <ModoProvider>
//               <Navbar />
//               <Routes>
//                 <Route path='/' element={<Contenedor />} />
//                 <Route path='/comunicados/' element={<Comunicados />} />
//                 <Route path='/convenios/:itemId' element={<ItemDetailContainer />} />
//                 <Route path='/categoria/:tipoCategoria' element={<Categorias />} />
//                 <Route path='/favoritos' element={<Favoritos />} />
//               </Routes>
//             </ModoProvider>
//             <Footer />
//             {isMobile && <MobileBottomBar />}

//             {/* Modal personalizado para la instalación de la PWA */}
//             {showInstallPrompt && (
//               <InstallPromptModal 
//                 onInstallClick={handleInstallClick}
//                 onClose={() => setShowInstallPrompt(false)}
//               />
//             )}
//           </div>
//         </SearchProvider>
//       </BrowserRouter>
//     </div>
//   );
// }

// export default App;





import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NavSuperior from './components/NavSuperior/NavSuperior';
import NavSuperiorMobile from './components/NavSuperior/NavSuperiorMobile';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Contenedor from './components/Contenedor/Contenedor';
import ItemDetailContainer from './components/ItemDetailContainer/ItemDetailContainer';
import Categorias from './components/Categorias/Categorias';
import Favoritos from './components/Favoritos/Favoritos';
import { ModoProvider } from './components/ModoContext/ModoContex';
import { SearchProvider } from './components/SearchContext/SearchContext';
import MobileBottomBar from './components/NavSuperior/MobileBottomBar';
import InstallPromptModal from './components/InstallPromptModal/InstallPromptModal.jsx';  // Modal personalizado
import './app.btninstall.css';
import BeneficiosPersonales from './components/BeneficiosPersonales/BeneficiosPersonales.jsx';
import BeneficiosDetail from './components/beneficiosDetail/BeneficiosDetail.jsx';
function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallPrompt, setShowInstallPrompt] = useState(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Registrar el Service Worker
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js')
          .then((registration) => {
            console.log('Service Worker registrado con éxito:', registration);
          })
          .catch((error) => {
            console.log('Error al registrar el Service Worker:', error);
          });
      });
    }
  }, []);

  // Manejar el evento beforeinstallprompt para mostrar el modal
  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault(); // Prevenir que se muestre automáticamente
      setDeferredPrompt(e); // Guardar el evento para usarlo más tarde
      console.log("Se detectó el evento beforeinstallprompt");

      // Retrasar la aparición del modal 3 segundos para pruebas (puedes cambiar esto)
      setTimeout(() => {
        setShowInstallPrompt(true); // Mostrar el modal después del retraso
      }, 3000); // Cambia esto a 3000 ms para pruebas
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  // Función para manejar la instalación de la PWA cuando el usuario presione el botón
  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt(); // Mostrar el prompt de instalación
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('El usuario aceptó la instalación');
        } else {
          console.log('El usuario rechazó la instalación');
        }
        setDeferredPrompt(null); // Limpiar el prompt después de la respuesta
        setShowInstallPrompt(false); // Cerrar el modal
      });
    }
  };

  return (
    <div className="App">
      <BrowserRouter>
        <SearchProvider>
          <div className="content-wrap">
            {isMobile ? <NavSuperiorMobile /> : <NavSuperior />}
            <ModoProvider>
              <Navbar />
              <Routes>
                <Route path='/' element={<Contenedor />} />
                <Route path='/beneficios_personales/' element={<BeneficiosPersonales />} />
                <Route path='/beneficios_personales/:id' element={<BeneficiosDetail />} />
                <Route path='/convenios/:itemId' element={<ItemDetailContainer />} />
                <Route path='/categoria/:tipoCategoria' element={<Categorias />} />
                <Route path='/favoritos' element={<Favoritos />} />
              </Routes>
            </ModoProvider>
            <Footer />
            {isMobile && <MobileBottomBar />}

            {/* Modal personalizado para la instalación de la PWA */}
            {showInstallPrompt && (
              <InstallPromptModal 
                onInstallClick={handleInstallClick}
                onClose={() => setShowInstallPrompt(false)}
              />
            )}
          </div>
        </SearchProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
