

import './footer2.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer-container">
      <div className="footer-links">
        <a href="/" className="footer-link">Convenios</a>
        <a href="/comunicados" className="footer-link">Beneficios Personales</a>
      </div>
      
      <div className="footer-top-line"></div>
      
      <div className="footer-content">
        
        <div className="footer-section">
          <h4>SEDE NACIONAL:</h4>
          <p>9 de Julio Oeste 647, Capital, San Juan | </p>
          <a href="mailto:info@asijemin.org.ar">info@asijemin.org.ar</a>
        </div>
       
        <div className="footer-section">
          <h4>DEL REGIONAL CENTRO:</h4>
          <p>Pedro de Valdivia 1964 (oeste) - San Juan | </p>
          <a href="mailto:info@asijemin.org.ar">info@asijemin.org.ar</a>
        </div>
        <div className="footer-section">
          <h4>DEL REGIONAL SUR - PTO. SAN JULIÁN:</h4>
          <p>Mitre 963, Puerto San Julián, Santa Cruz | </p>
          <a href="mailto:administracion.secsur@asijemin.org.ar">administracion.secsur@asijemin.org.ar</a> | 
          <a href="mailto:info@asijemin.org.ar">info@asijemin.org.ar</a>
        </div>
        <div className="footer-section">
          <h4>DEL REGIONAL SUR - PERITO MORENO:</h4>
          <p>Av. Perón 1730, Perito Moreno, Santa Cruz | </p>
          <a href="mailto:sedeperitomoreno@asijemin.org.ar">sedeperitomoreno@asijemin.org.ar</a>
        </div>
      </div>

      <div className="footer-bottom">
        <p>&copy; Todos los derechos reservados <strong>ASIJEMIN</strong> {currentYear}</p>
      </div>
    </div>
  );
};

export default Footer;
